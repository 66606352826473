.chat-btn {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1000;
  width: 60px;
  height: 60px;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0.2rem 0.4rem -0.1rem rgba(0, 0, 0, 0.1),
    0 0.4rem 0.5rem 0 rgba(0, 0, 0, 0.07), 0 0.1rem 1rem 0 rgba(0, 0, 0, 0.06);
  transition: all 0.2s;
}

.chat-btn:hover,
.chat-btn:active {
  box-shadow: 0 0.2rem 0.4rem -0.1rem rgba(0, 0, 0, 0.4),
    0 0.4rem 0.5rem 0 rgba(0, 0, 0, 0.15), 0 0.1rem 1rem 0 rgba(0, 0, 0, 0.12);
}

.chat-btn__img {
  width: 100%;
  height: auto;
}

/* .whatsapp-btn-window-form {
  box-shadow: 1px 2px 8px rgba(60, 60, 60, 0.25);
  border-radius: 4px;
  width: 400px;
  position: fixed;
  right: 20px;
  bottom: 88px;
  z-index: 1000;
  border: solid 1px #bbb;
  background-color: #fff;
}

.whatsapp-btn-window {
  box-shadow: 1px 2px 8px rgba(60, 60, 60, 0.25);
  border-radius: 4px;
  width: 260px;
  position: fixed;
  right: 20px;
  bottom: 88px;
  z-index: 1000;
  border: solid 1px #bbb;
}

.whatsapp-btn-window-header {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #007bed;
  padding: 0.3rem 0.3rem 0.3rem 0.8rem;
  display: flex;
}

.whatsapp-btn-window-message-body {
  background-color: #a3d6ff;
  padding: 0.8rem;
}

.whatsapp-btn-window-message-form-container {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #fff;
  padding: 0.8rem;
}

.whatsapp-btn-window-message-form {
  display: grid;
  grid-template-columns: 167px 48px;
  grid-gap: 7px;
}

@media screen and (max-width: 601px) {
  .whatsapp-btn-window-form {
    width: 320px;
  }
} */
