@import "../../../styles/abstracts/animations";
@import "../../../styles/abstracts/mixins";
@import "../../../styles/abstracts/variables";

a:-webkit-any-link {
  color: inherit;
  text-decoration: none;
}

.mobile-nav-links {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  position: relative;
  box-sizing: border-box;
  padding: 10px 0 40px;
  overflow-x: hidden;

  &__whitelabel-container {
    margin-top: 2rem;
    width: 100%;
    height: auto;
    overflow: hidden;
    object-fit: cover;
    @include center-xy-axis-horizontal;
  }

  &__whitelabel-image {
    width: 80px;
    height: auto;
  }
}

.nav-close {
  width: 24px;
  position: relative;
  top: 5px;
  left: 5px;
  z-index: 10;
}

.mobile-nav-links li {
  width: 100%;
  display: flex;
  align-items: center;
}

.nav-division {
  border-bottom: 1px solid #d6d6d6;
}

.nav-division--no-border {
  border-bottom: 0 solid #d6d6d6;
}

.mobile-nav-links span,
.mobile-nav-links svg {
  text-align: left;
  justify-content: left;
}

.mobile-nav-links svg {
  padding-right: 1rem;
}

.side-nav__btn {
  & span {
    padding: 0.5rem 1rem;
    transition: all 0.2s;
    color: #263351;
  }

  &--premium span,
  &--premium svg {
    color: #897f33 !important;
  }
}

.side-nav__btn--teal span {
  padding: 0.5rem 1rem;
  transition: all 0.2s;
  color: #009688;
}

.side-nav__btn--teal svg {
  color: #00897b;
}

.side-nav__btn--premium .side-nav__icon-premium {
  font-size: 1.1rem;
  margin-left: 0.25rem;
}

.nav-secondary-item span {
  font-size: 0.75rem;
  padding-left: 2rem;
  color: #6a79a1;
}

.side-nav__btn--active span {
  color: #007bed;
}
