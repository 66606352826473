.blocker {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  background: #00000099;
  z-index: 99999;

  &__container {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 30%;
    height: 100%;
    backdrop-filter: blur(4px);

    &__note {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      color: #fff;

      &__icon {
        svg {
          font-size: 4rem;
        }
      }

      &__actions {
        margin-top: 2rem;
      }
    }
  }
}