.chat-audio-player {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 300px;

  &__content {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    &__left-container {
      display: flex;
      justify-content: center;
      align-items: center;

      span.MuiIconButton-root {
        padding: 6px;
      }
    }
  
    &__right-container {
      display: flex;
      flex-direction: column;
      flex: 1;

      &__wave {
        width: 100%;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    padding-left: 3rem;
  }
}